var stickyHeader = true;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = true;
var countUpStats = true;
var countUpStatsDuration = 3000;
var subsiteHijackMainNav = [
  "marc",
  "enjoy",
  "pitch"
];
var subsiteHijackMainLogoClickDestination = [
  "marc",
  "enjoy",
  "pitch"
];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Make external menu admin link open in a new tab
$("#menuAdmin a[href*='movingpictures.org.au']").attr({
  target: "_blank",
  rel: "noopener noreferrer",
});

// Footer copyright
$(".copy-year").text(new Date().getFullYear());

// Subsite socials
$(".subsite.marc .socialIcons a.twitter").attr("href", "https://twitter.com/Melb_ARC");
$(".subsite.marc .socialIcons a.linkedin").attr("href", "https://au.linkedin.com/showcase/the-melbourne-ageing-research-collaboration-marc");

// Subsite cta - change this to a 'NARI main site' button
$("<a class='nariLogo' href='/' />").insertAfter("body.subsite .mainLogo");


// Recenter and zoom map
$(window).bind("load", function () {
  CenterMap(new google.maps.LatLng(-36.823273, 144.294413), 6);
});

// Map items - open in new tab
$(".listedPost.listedMapItem h2 a").prop({"target": "_blank", "rel": "noopener noreferrer"});

$('.mainLogo').clone().addClass('nari').attr('href', 'https://www.nari.net.au/').insertAfter($('.mainLogo'));